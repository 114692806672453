.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.footer-card{
  position: absolute;
  bottom: 15px;
  left: 185px;
}

.logo > img {
  height: 60px !important;
}

.btn-margin-left {
  margin-left: 15px;
}

#inputFile{
  display: none !important;
}

